export const environment = {
  applicationInsights: {
    appId: "Identity",
    enableConsoleLogging: true,
    instrumentationKey: "ca707ff2-fe25-44b7-9999-0f1da7c7dece",
    rethrowException: false,
  },
  auth: {
    authority: "https://devel-auth.motorcity.systems",
    clientId: "id",
  },
  gaugeBaseUrl: "https://devel-gauge-api.motorcity.systems",
  identityBaseUrl: "https://devel-identity-api.motorcity.systems",
  geolocationBaseApiUrl: "https://devel-geoloc-api.motorcity.systems",
  adminBaseUrl: "https://devel-admin-api.motorcity.systems",
  name: "dev",
  production: true,
  version: {
    hash: "{{POST_BUILD_ENTERS_VERSION_HASH_HERE}}",
    version: "{{POST_BUILD_ENTERS_VERSION_NUMBER_HERE}}",
  },
  identityAppUrl: "https://devel-identity.motorcity.systems",
};
